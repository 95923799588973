$(document).foundation();
$(document).ready(function () {

    // redirect na ww wijzigen
    if ($('#Block_ChangePassword_pnl_changepassword').length > 0) {
        if ($('#lbl_successmessage').length > 0) {
            $(location).attr('href', 'https://www.seh.nl/home')
        }
    }

    var slider = $('.block.slider.variant1');
    if ($(slider).length > 0) {
        $(slider).find(".small-12.medium-12.large-8.columns").attr('class', 'small-12.medium-12.large-12.columns');
    }

    if ($('.block.login').length > 0) {
        $('#input_container_username input').attr("placeholder", "Vul uw gebruikersnaam in");
        $('#input_container_password input').attr("placeholder", "Vul uw wachtwoord in");
        $('#login_retrievepassword_container').insertBefore("#btn_login");
    }

    if ($('#top-menu').length > 0) {
        //login
        $('<strong class="intro">Inloggen voor mijn SEH</strong>').insertBefore('#lbl_username');
        var loginBtn = $('#login span');
        $(loginBtn).click(function (e) {
            $(this).next('.inner').toggle();
            e.preventDefault();
        });

        // logout
        var logoutBtn = $('#Block_Header_PersonName');
        $(logoutBtn).click(function (e) {
            $('#logOut').toggleClass('open');
            e.preventDefault();
        });

    }

    // Set background images
    if ($('.bgimage img').length > 0) {
        processBackgroundImages();
    }

    // remove login popup on login page
    function getPageID(name) {
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        var results = regex.exec(document.getElementsByTagName("form")[0].getAttribute("action"));

        if (results == null)
            return "";
        else
            return results[1];
    }

    var pageID = getPageID('id');
    if (pageID === '316') { // inlog muur
        $('#login .inner').remove();
        // console.log('login modal removed');
    }


    // if (pageID === '855') {
    //     var s = document.createElement("script");
    //     s.type = "text/javascript";
    //     s.src = "https://unpkg.com/isotope-layout@3/dist/isotope.pkgd.min.js";
    //     $("head").append(s);
    // }

});

$(document).ajaxStop(function () {

    // remove login popup on login page
    function getPageID(name) {
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        var results = regex.exec(document.getElementsByTagName("form")[0].getAttribute("action"));

        if (results == null)
            return "";
        else
            return results[1];
    }

    // var pageID = getPageID('id');
    // if (pageID === '855'){
    //     // functions
    //     isotope();
    // }


    $('#options .filterTopic strong').on("click", function () {
        $(this).parent('div').toggleClass('open');
    });

    // search
    $('.searchIcon').click(function (e) {
        e.preventDefault();
        $('section#search').fadeToggle(350);
    });

    // show answer for SEH.pages.xslt
    if ($('.block.pages #subjects').length > 0) {
        $("a.toggleAnswer").click(function (e) {
            e.preventDefault();
            $(this).next('.answer').toggle();
            $(this).text(function (i, v) {
                return v === 'Toon antwoord' ? 'Verberg antwoord' : 'Toon antwoord'
            })
        });

        // group tab toggle
        $("h4.title").click(function (e) {
            e.preventDefault();
            $(this).find('span.fa.fa-chevron-right').toggleClass('rotate');
            $(this).next('ul').toggle();
        });
    }

    // get friend URL of active page for crumble path
    if ($('.intro h2').length > 0) {
        var titel = $('.intro h2').text();
        $("span.active").append(titel);
    }

    if ($('.zoekresultaatHead').length > 0) {
        $('.zoekresultaatHead').appendTo('.resultsFound');
    }

    // if ($('div#options').length > 0) {
    //     // $(".reload a").click(function() {
    //     //     $(".filterContent input").prop("checked", false);
    //     // });
    //
    //     if ($('input[type="checkbox"]').is(':checked')){
    //         $(".reload").show();
    //     } else {
    //         $(".reload").hide();
    //     }
    // }

});

// smooth scroll icon homepage
if ($('.arrow.bounce').length > 0) {
    $('.bounce').on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    });
}

$(function () {
    subNav = function (elm) {
        $(elm).toggleClass('rotate');
        $(elm).next("ul.dropdown").toggle();
    };
});

var standardSearchValidation = function (formId, errorId) {
    var veld = $("#" + formId).find("input:text");
    var error = $("#" + errorId);
    veld.blur();
    $(error).fadeIn();
    $(document).one('mousedown keydown scroll', function (event) {
        $(error).fadeOut();
        event.stopPropagation();
        veld.focus();
    });
};

var openNav = function () {
    $("#mobileNav").css("width", "75%");
    $("#hamburger").hide();
};

var closeNav = function () {
    $("#mobileNav").css("width", "0px");
    $("#hamburger").show();
};

var processBackgroundImages = function () {
    $('.bgimage').each(function () {
        var src = $(this).find('img').attr('src');
        if (src) {
            $(this).css('background-image', 'url("' + src + '")');
        }
    });
};

var isotope = function () {

    removeDuplicates();

    // store filter per group
    var filters = {};
    var $container = $('#container').isotope({
        itemSelector: '.item',
        layoutMode: 'fitRows',
    });

    var $filterDisplay = $('#filter-display');

    $container.isotope();
    // do stuff when checkbox change
    $('#options').on('change', function (event) {

        toggleButton();

        var checkbox = event.target;
        var $checkbox = $(checkbox);
        var group = $checkbox.parents('.option-set').attr('data-group');
        // create array for filter group, if not there yet
        var filterGroup = filters[group];
        if (!filterGroup) {
            filterGroup = filters[group] = [];
        }
        // add/remove filter
        if (checkbox.checked) {
            // add filter
            filterGroup.push(checkbox.value);
        } else {
            // remove filter
            var index = filterGroup.indexOf(checkbox.value);
            filterGroup.splice(index, 1);

        }

        var comboFilter = getComboFilter();
        $container.isotope({filter: comboFilter});
        $filterDisplay.text(comboFilter);
    });

    function getComboFilter() {
        var combo = [];
        for (var prop in filters) {
            var group = filters[prop];
            if (!group.length) {
                // no filters in group, carry on
                continue;
            }
            // add first group
            if (!combo.length) {
                combo = group.slice(0);
                continue;
            }
            // add additional groups
            var nextCombo = [];
            // split group into combo: [ A, B ] & [ 1, 2 ] => [ A1, A2, B1, B2 ]
            for (var i = 0; i < combo.length; i++) {
                for (var j = 0; j < group.length; j++) {
                    var item = combo[i] + group[j];
                    nextCombo.push(item);
                }
            }
            combo = nextCombo;
        }
        var comboFilter = combo.join(', ');
        return comboFilter;
    }
}

function removeDuplicates() {
    var seen = {};
    $('#options input').each(function () {
        var name = $(this).val();
        if (seen[name])
            $(this).parent('label').remove();
        else
            seen[name] = true;
    });

    // var seenLabels = {};
    // $('#options .datum label').each(function () {
    //     var labelName = $(this).text();
    //     if (seenLabels[labelName])
    //         $(this).remove();
    //     else
    //         seenLabels[labelName] = true;
    // });
}
function toggleButton() {
    if ($('input[type="checkbox"]').is(':checked')) {
        $(".reload").fadeIn();
    } else {
        $(".reload").fadeOut();
    }

    //
    $(".reload a").click(function () {
        location.reload();
    });
}


// var isotope = function() {
//
//     console.log('isotope function called');
//     removeDuplicates();
//     // editValues();
//
//     // init Isotope
//         var $container = $('#container').isotope({
//             itemSelector: '.item',
//             // layoutMode: 'masonry',
//             layoutMode: 'fitRows',
//         });
//
//     var $output = $('#output');
//
//     // filter with selects and checkboxes
//     var $checkboxes = $('#form-ui input');
//
//     $checkboxes.change(function() {
//         console.log('checkbox change function');
//         // map input values to an array
//         var inclusives = [];
//         // inclusive filters from checkboxes
//         $checkboxes.each(function(i, elem) {
//             // if checkbox, use value if checked
//             if (elem.checked ) {
//                 inclusives.push(elem.value);
//             }
//         });
//
//         // combine inclusive filters
//         var filterValue = inclusives.length ? inclusives.join(', ') : '*';
//
//         $output.text(filterValue);
//         $container.isotope({ filter: filterValue })
//     });
//
//     // quick search regex
//     var qsRegex;
//
//     // init Isotope
//     var $grid = $('#container').isotope({
//         itemSelector: '.item',
//         filter: function() {
//             return qsRegex ? $(this).text().match( qsRegex ) : true;
//         }
//     });
//
//     // use value of search field to filter
//     var $quicksearch = $('.quicksearch').keyup(debounce(function() {
//         qsRegex = new RegExp( $quicksearch.val(),'gi');
//         $grid.isotope();
//     },200));
//
//     // debounce so filtering doesn't happen every millisecond
//     function debounce(fn, threshold) {
//         console.log('function debounce');
//         var timeout;
//         threshold = threshold || 100;
//         return function debounced() {
//             clearTimeout(timeout);
//             var args = arguments;
//             var _this = this;
//             function delayed() {
//                 fn.apply(_this, args);
//             }
//             timeout = setTimeout( delayed, threshold );
//         };
//     }
//
//     function removeDuplicates() {
//         console.log('remove duplicates function');
//         var seen = {};
//         $('#form-ui input').each(function () {
//             var name = $(this).val();
//             if (seen[name])
//                 $(this).parent('label').remove();
//             else
//                 seen[name] = true;
//         });
//
//         console.log('remove duplicate dates');
//
//         var seenLabels = {};
//         $('#form-ui .datum label').each(function () {
//             var labelName = $(this).text();
//             if (seenLabels[labelName])
//                 $(this).remove();
//             else
//                 seenLabels[labelName] = true;
//         });
//     }
//
//     // function editValues() {
//     //     $("input[name='custom']").each(function(index, el) {
//     //         // var val = el.value.replace(/\s/g, "");
//     //         var val = el.value.replace(/[_\W]+/g, "-");
//     //         console.log(index + ": " + $(this).val());
//     //         alert(val);
//     //     });
//     // }
// };

